export default class ExportExcelCriteria {
  constructor({
    localTrainingId,
    modalityId,
    categoryId,
    teacherId,
    socialEntityId,
    classId
  } = {}) {
    this.localTrainingId = localTrainingId
    this.modalityId = modalityId
    this.categoryId = categoryId
    this.teacherId = teacherId
    this.socialEntityId = socialEntityId
    this.classId = classId
  }
}
