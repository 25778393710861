import ReportStudentRepository from '@/shared/http/repositories/socialProject/report-student.js'
import ModalityLocalTrainingRepository from '@/shared/http/repositories/socialProject/modalitylocaltraining'
import SocialEntityRepository from '@/shared/http/repositories/socialProject/social-entity'
import CategoryRepository from '@/shared/http/repositories/socialProject/category'
import TeacherRepository from '@/shared/http/repositories/socialProject/teacher'
import ClassRepository from '@/shared/http/repositories/socialProject/class'
import ExportExcelCriteria from '@/shared/models/criteria/exportexcelcriteria'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import Pagination from '@/components/layout/Pagination/pagination.vue'
import { dateFormattedFilter } from '@/shared/filters/ToDateFormatted'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'
import { phoneFilter } from '@/shared/filters/ToPhoneFormatted.js'
import Roles from '@/shared/mixins/hasRoles'
import download from 'downloadjs'

export default {
  components: {
    [BreadCrumb.name]: BreadCrumb,
    [Pagination.name]: Pagination
  },

  mixins: [Roles],

  data: () => ({
    criteria: new ExportExcelCriteria(),
    modalityLocalTraining: [],
    listLocalTraining: [],
    optionsTeachers: [],
    optionsClass: [],
    socialEntities: [],
    categories: [],
    modalities: [],
    students: [],
    emptyText: 'Faça uma pesquisa para o preenchimento desta tabela',
    enableClassInput: false,
    totalRegister: 0,
    fields: [
      {
        key: 'code',
        label: 'CÓDIGO',
        class: 'left-header-border text-center'
      },
      {
        key: 'name',
        label: 'NOME',
        class: 'middle-header text-center'
      },
      {
        key: 'cpf',
        label: 'CPF',
        class: 'middle-header text-center'
      },
      {
        key: 'rg',
        label: 'RG',
        class: 'middle-header text-center'
      },
      {
        key: 'gender',
        label: 'SEXO',
        class: 'middle-header text-center'
      },
      {
        key: 'age',
        label: 'IDADE',
        class: 'middle-header text-center'
      },
      {
        key: 'socialEntityName',
        label: 'ENTIDADE',
        class: 'middle-header text-center'
      },
      {
        key: 'modalityName',
        label: 'MODALIDADE',
        class: 'middle-header text-center'
      },
      {
        key: 'localTrainingName',
        label: 'LOCAL DE TREINAMENTO',
        class: 'middle-header text-center'
      },
      {
        key: 'healthPlanName',
        label: 'PLANO DE SAÚDE',
        class: 'middle-header text-center'
      },
      {
        key: 'schoolName',
        label: 'ESCOLA',
        class: 'middle-header text-center'
      },
      {
        key: 'familyIncome',
        label: 'RENDA FAMILIAR',
        class: 'middle-header text-center'
      },
      {
        key: 'familyBag',
        label: 'BOLSA FAMÍLIA',
        class: 'middle-header text-center'
      },
      {
        key: 'motherName',
        label: 'NOME - MÃE',
        class: 'middle-header text-center'
      },
      {
        key: 'fatherName',
        label: 'NOME - PAI',
        class: 'middle-header text-center'
      },
      {
        key: 'phone',
        label: 'TELEFONE',
        class: 'middle-header text-center',
        formatter: phoneFilter
      },
      {
        key: 'phone2',
        label: 'TELEFONE - 2',
        class: 'middle-header text-center',
        formatter: phoneFilter
      },
      {
        key: 'phone3',
        label: 'TELEFONE - 3',
        class: 'middle-header text-center',
        formatter: phoneFilter
      },
      {
        key: 'birthDate',
        label: 'DATA NASCIMENTO',
        class: 'middle-header text-center',
        formatter: dateFormattedFilter
      },
      {
        key: 'status',
        label: 'STATUS',
        class: 'middle-header text-center'
      },
      {
        key: 'yearRegistration',
        label: 'ANO REMATRÍCULA',
        class: 'middle-header text-center',
        formatter: dateFormattedFilter
      },
      {
        key: 'dateLastUpdate',
        label: 'DATA ÚLTIMA ALTERAÇÃO',
        class: 'right-header-border text-center',
        formatter: dateFormattedFilter
      }
    ],
    pagination: {
      count: 1,
      limit: 15,
      index: 1
    }
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Exportar em Excel',
            active: true
          }
        ]
      }
    },
    disable() {
      this.criteria.localTrainingName = null
      this.criteria.modalityName = null
      const hasValues = Object.values(this.criteria).some(v => v !== null && v !== undefined)

      if (hasValues) {
        return false
      }

      return true
    }
  },

  created() {
    this.clearForms()
    this.getAllTeacher()
    this.getAllSocialEntity()
    this.loadModalityLocalTraining()
  },

  methods: {
    clearForms() {
      this.criteria = new ExportExcelCriteria()
    },

    getAll() {
      loading.push()
      ReportStudentRepository.GetAllByCriteria(this.criteria, this.pagination)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          if(res.data.count === 0) {
            toast.warning('Esta consulta não tem registros')
            this.students = []
            this.totalRegister = 0
            loading.pop()
            return Promise.resolve()
          }

          this.totalRegister = res.data.count

          this.students = res.data.data
          this.pagination = {
            count: res.data.count,
            limit: res.data.pagingLimit,
            index: res.data.pagingIndex
          }
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar alunos', 'ERRO')
        })
    },

    downloadExcel() {
      loading.push()
      ReportStudentRepository.ExportExcel(this.criteria)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          if(res.data.data === null) {
            toast.warning('Esta consulta não tem registros e não irá gerar planilha')
            loading.pop()
            return Promise.resolve()
          }

          const file = res.data.data
          const base64 = `data:${file.contentType};base64,${file.base64}`
          download(base64, file.alias, file.contentType)

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao exportar excel', 'ERRO')
        })
    },

    getAllTeacher() {
      loading.push()
      TeacherRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.optionsTeachers = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar professores', 'ERRO')
        })
    },

    getClass() {
      this.enableClassInput = true
      this.criteria.localTrainingId = null
      this.criteria.modalityId = null
      this.criteria.categoryId = null
      this.criteria.socialEntityId = null

      loading.push()
      ClassRepository.GetAll(this.criteria)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.optionsClass = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar turmas', 'ERRO')
        })
    },

    disableInputs() {
      this.criteria.teacherId = null

      if(!this.criteria.classId) {
        this.enableClassInput = false
      }
    },

    getAllSocialEntity() {
      loading.push()
      SocialEntityRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.socialEntities = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar entidades sociais', 'ERRO')
        })
    },

    loadModalityLocalTraining() {
      this.modalityLocalTraining = []
      loading.push()
      ModalityLocalTrainingRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.modalityLocalTraining = res.data.data
          this.onSelectModality()
          this.onSelectLocalTraining()

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Não foi possível carregar a lista de Modalidades e Locais de treinamento.', 'ERRO')
        })
    },

    onSelectLocalTraining() {
      let list = this.modalityLocalTraining

      if (this.criteria.localTrainingId) {
        const type = this.listLocalTraining.filter(s => s.id === this.criteria.localTrainingId)

        if (type.length > 0) {
          this.criteria.localTrainingName = type[0].name
          list = this.modalityLocalTraining.filter(s => s.localTrainingId === type[0].id)
        }
      }

      this.modalities = []
      for (var i = 0; i < list.length; i++) {
        let modality = {
          id: list[i].modalityId,
          name: list[i].modalityName
        }

        if (this.modalities.filter(m => m.id === modality.id).length === 0) {
          this.modalities.push(modality)
        }
      }
    },

    onSelectModality() {
      let list = this.modalityLocalTraining

      if (this.criteria.modalityId) {
        let type = this.modalities.filter(s => s.id === this.criteria.modalityId)

        if (type.length > 0) {
          this.criteria.modalityName = type[0].name
          list = this.modalityLocalTraining.filter(s => s.modalityId === type[0].id)
        }
      }

      this.listLocalTraining = []
      for (var i = 0; i < list.length; i++) {
        const local = {
          id: list[i].localTrainingId,
          name: list[i].localTrainingName
        }

        if (this.listLocalTraining.filter(l => l.id === local.id).length === 0) {
          this.listLocalTraining.push(local)
        }
      }

      this.categories = []
      if (this.criteria.modalityId) {
        CategoryRepository.GetAllByModality(this.criteria.modalityId)
          .then(response => {
            this.categories = response.data.data
          })
          .catch(() => {
            toast.error('Não foi possível carregar a lista de Categorias.')
          })
      }
    }
  }
}
